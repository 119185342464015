/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


  export const onServiceWorkerUpdateFound = () => {
    // const doSomething = () => console.log('did something!');
    // const doSomethingElse = () => console.log('did something else!');
    const showNotification = () => {
      Notification.requestPermission(result => {
          if (result === 'granted') {
              navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification('Update', {
                      body: 'New content is available soon! Check wynluther.com or your app\n in a few minutes for fresh pics.',
                      icon: './src/images/gatsby-icon.png',
                      vibrate: [200, 100, 200, 100, 200, 100, 400],
                      tag: 'request',
                      actions: [ // you can customize these actions as you like
                          {
                              action: 'I updated', // you should define this
                              title: 'update',
                          },
                      ]
                  })
              })
          }
      })
    }
    showNotification()
  }